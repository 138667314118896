<template>
    <page heading="New Album">
        <input-field
            label="Name"
            name="name"
            id="name"
            v-model="name"
        />

        <multiselect
            :options="categories"
            :searchable="true"
            v-model="selectedCategories"
            value-prop="id"
            track-by="name"
            label="name"
            mode="tags"
        />

        <image-uploader v-model="images" />

        <v-button
            @click="submitForm"
            :loading="submitting"
            :disabled="images.length === 0 || uploading"
        >
            Create
        </v-button>
    </page>
</template>

<script>
import { mapState } from 'vuex';
import Multiselect from '@vueform/multiselect';
import Page from '../../layouts/Page.vue';
import InputField from '../../components/Form/Input.vue';
import VButton from '../../components/Action/VButton.vue';
import store from '../../store';
import to from '../../plugins/to';
import api from '../../plugins/api';
import ImageUploader from '../../components/Action/ImageUploader/ImageUploader.vue';

export default {
    name: 'AlbumsCreate',
    components: {
        ImageUploader,
        VButton,
        InputField,
        Page,
        Multiselect,
    },
    async beforeRouteEnter() {
        await store.dispatch('categories/fetchCategories');
    },
    computed: {
        ...mapState('categories', ['categories']),
        ...mapState('imageUploader', ['uploading']),
    },
    methods: {
        async submitForm() {
            this.submitting = true;

            const [err, res] = await to(api.post('/albums', {
                name: this.name,
                images: this.images.map((image) => image.id),
                categories: this.selectedCategories,
            }));

            if (!err) {
                const { data: { data: album } } = res;

                await this.$router.push({ name: 'dashboard.albums.show', params: { albumId: album.id } });
            } else {
                // TODO
            }

            this.submitting = false;
        },
    },
    data: () => ({
        name: '',
        images: [],
        selectedCategories: [],
        submitting: false,
    }),
};
</script>

<style lang="scss" scoped>
@import '~@vueform/multiselect/themes/default.css';

.multiselect {
    margin-bottom: 2rem;
}
</style>
