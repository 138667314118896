<template>
    <ul class="field-errors">
        <li
            class="field-errors__error"
            v-for="(error, i) in errors"
            :key="i"
            v-text="error"
        />
    </ul>
</template>

<script>
export default {
    name: 'FieldErrors',
    props: {
        errors: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss">
.field-errors {
    list-style-type: none;

    &__error {
        color: var(--form-error-color);
        font-size: 1.2rem;
        margin: -2rem 0 0 0;
    }
}
</style>
