<template>
    <div class="field">
        <label
            v-if="label"
            class="label"
            v-text="label"
            :for="identifier"
        />
        <input
            :type="type"
            class="input"
            :name="name"
            :id="identifier"
            @input="$emit('update:modelValue', $event.target.value)"
        >
        <field-errors
            :errors="errors"
        />
    </div>
</template>

<script>
import FieldErrors from './FieldErrors.vue';

export default {
    name: 'InputField',
    components: { FieldErrors },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        name: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        identifier() {
            return this.id || this.name;
        },
    },
};
</script>

<style lang="scss" scoped>
.input {
    background-color: var(--input-bg-color);
}
</style>
